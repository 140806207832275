<template>
  <div>
    <v-form>
      <v-card>
        <v-card-text>
          <v-row>
            <v-col :cols="colSize">
              <v-select
                v-model="form.sync_ids"
                placeholder="Select 0 or more distributors"
                :items="distributorOptions"
                item-text="title"
                item-value="value"
                label="Distributors"
                outlined
                chips
                multiple
                hide-details
                @change="handleSync"
              ></v-select>
            </v-col>
            <v-col :cols="colSize">
              <!-- <v-select
                  v-model="form.type"
                  placeholder="Display Section"
                  :items="typeOptions"
                  item-text="title"
                  item-value="value"
                  outlined
                  dense
                  hide-details
                ></v-select> -->
            </v-col>
          </v-row>
        </v-card-text>

        <v-alert
          v-for="msg in errors"
          :key="msg"
          color="warning"
        >
          {{ msg }}
        </v-alert>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import { useDistributor } from '@/composables'
import axios from '@axios'
import { } from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    colSize: {
      type: Number,
      default: 6,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    selectedIds: {
      // default selected ids
      type: Array,
      default() {
        return []
      },
    },
    syncBasePath: {
      // example: /admin/products/1/sync-distributors
      type: String,
      required: true,
    },
    syncAble: {
      type: Boolean,
      default: true,
    },
  },

  watch: {
    syncAble: 'handleSync',
  },

  setup(props, { emit }) {
    // const typeOptions = [
    //   { title: 'Service Category', value: 'service' },
    //   { title: 'Tangible Category', value: 'tangible' },
    // ]
    const { distributorOptions } = useDistributor()

    const initialForm = {
      sync_ids: props.selectedIds,
    }

    const form = ref({ ...initialForm })

    const handleSync = () => {
      if (!props.syncAble) return

      axios.post(props.syncBasePath, form.value).then(res => {
        console.log('sync-ed', res)
        emit('changed')

        return Promise.resolve(res)
      })
    }

    const made = async resource => {
      console.log('made resource', resource)

      form.value.sync_ids.push(resource.id)
      await handleSync()
    }

    return {
      form,

      // typeOptions,
      distributorOptions,
      handleSync,
      made,
    }
  },
}
</script>
